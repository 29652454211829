import { PosterLocation } from './poster.location.model';

export class MarkerModel {
   id: string;
   lat: string;
   lng: string;
   label: string;
   infoHead: string;
   infoText: string;
   typeText: string;
   warningText: string;
   iconUrl: string;
   constructor(posterLocation: PosterLocation, infoText: string, typeText: string, warningText: string, iconUrl: string) { 
      this.id = posterLocation.id;
      this.lat = posterLocation.address.latitude;
      this.lng = posterLocation.address.longitude;
      this.label = '';
      this.infoHead = posterLocation.address.street + ' ' + posterLocation.address.streetNumber + ', ' + posterLocation.address.city;      
      this.infoText = infoText;
      this.typeText = typeText;
      this.warningText = warningText;
      this.iconUrl = iconUrl;
   }
} 