import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContentHeaders } from '../../common/headers';
import { LoginModel } from '../../models/login.model';
import { PrivilegesModel } from '../../models/privileges.model';
import { AppSettings } from '../../common/app.settings';
import { AuthenticationService } from '../../services/authentication.service';
import { PrivilegesService } from '../../services/privileges.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [UserService, PrivilegesService]
})
export class LoginComponent implements OnInit {
  loading = false;
  error = '';

  constructor(private router: Router,
    private authService: AuthenticationService,
    private userService: UserService,
    private privilegesService: PrivilegesService) { }

  ngOnInit() {
    // reset login status
  }

  login(event, username, password) {
    event.preventDefault();
    this.authService.login(username, password)
    .subscribe(result => {
        if (result === true) {
          this.userService.trackLogin()
          .then(() => {
              this.privilegesService.getPrivileges()
                  .subscribe(privileges => {
                      this.hasPrivileges(privileges);
                  });
              });
        } else {
            // login failed
            this.error = 'Benutzername oder Passwort falsch';
            this.loading = false;
        }
    });
  }

  hasPrivileges(privileges: PrivilegesModel): void {
      this.privilegesService.setMenuPrivileges();
      if (this.privilegesService.isAuthorized('home')) {
        this.router.navigate(['/']);
      } else {
        this.router.navigate(['/map']);
      }
  }
}
