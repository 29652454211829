import { Component, OnInit, ContentChild, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MapsAPILoader } from '@agm/core';
import { ErrorHandler } from '../../common/errorHandler';
import { PosterLocationService } from '../../services/poster.location.service';
import { PosterLocation } from '../../models/poster.location.model';
import { PosterLocationInput } from '../../models/poster.location.input.model';
import { MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { Address } from 'src/app/models/address.model';
import { ResultCode } from 'src/app/enums/resultcode.enum';

@Component({
  selector: 'app-home',
  templateUrl: './poster.location.detail.component.html',
  styleUrls: ['./poster.location.detail.component.css'],
  providers: [PosterLocationService, ErrorHandler]
})
export class PosterLocationDetailComponent implements OnInit {
  @ContentChild(MatFormFieldControl) control: MatFormFieldControl<any>;
  @ViewChild(MatFormField) matFormField: MatFormField;
  latitude: number;
  form: FormGroup;
  longitude: number;
  address: string;
  posterLocation: PosterLocation;
  private geoCoder;
  keys: any[];
  isDataAvailable = false;
  messages: Array<string>;

  constructor(
    private posterLocationService: PosterLocationService, 
    private mapsAPILoader: MapsAPILoader,
    private router: Router,
    private route: ActivatedRoute,
    private errorHandler: ErrorHandler) { 
      this.posterLocation = new PosterLocation();
      this.posterLocation.address = new Address();
  }

  ngOnInit(): void {  
    this.route.params.subscribe(params => { 
      if ((params['posterLocationId'] && params['posterLocationId'] === 'null') || params['posterLocationId'] == '00000000-0000-0000-0000-000000000000') {
        this.mapsAPILoader.load().then(() => {
        this.geoCoder = new google.maps.Geocoder;
        this.setCurrentLocation();
        });
      } else {
        this.posterLocationService.get(params['posterLocationId'])
        .then(posterLocation => {
          this.posterLocation = posterLocation;
          this.posterLocation.addressChanged = false;
        });
      }
    });
  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.address = '';
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  private getAddress(latitude: number, longitude: number) {
    let input: PosterLocationInput = new PosterLocationInput();
    input.latitude = latitude;
    input.longitude = longitude;
    input.isNew = true;
    this.posterLocationService.getPosterLocationsByGeoPosition(input)
        .then(apiResult => {
          let posterLocations: PosterLocation[] = apiResult.data;
          this.posterLocation = posterLocations[0];
          this.posterLocation.addressChanged = false;
          this.address = this.posterLocation.address.street + ' ' + this.posterLocation.address.streetNumber + ', ' + this.posterLocation.address.city;
          //this.isDataAvailable = true;
        })
        .catch(error => {
            this.errorHandler.do(error);
        });
  }

  update(): void {
    this.isDataAvailable = false;
    this.posterLocationService.update(this.posterLocation)
        .then(result => {
          console.log(result);
            if (result.code === ResultCode.Ok) {
                this.messages = null;
                //this.router.navigate(['home']);
            } else {
                this.messages = result.messages;
                this.isDataAvailable = true;
            }
        })
        .catch(error => {
            this.errorHandler.do(error);
            this.isDataAvailable = true;
        });
  } 

  add(): void {
    this.isDataAvailable = false;
    this.posterLocationService.add(this.posterLocation)
        .then(result => {
            if (result.code === ResultCode.Ok) {
                this.messages = null;
                //this.router.navigate(['home']);
            } else {
                this.messages = result.messages;
                this.isDataAvailable = true;
            }
        })
        .catch(error => {
            this.errorHandler.do(error);
            this.isDataAvailable = true;
        });
  } 

  onAddressChanged(): void {
    this.posterLocation.addressChanged = true;
    console.log('address changed');
  }
}
