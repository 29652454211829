<mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="street">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="street"> Straße </mat-header-cell>
      <mat-cell *matCellDef="let element" class="street"> {{element.address.street + ' ' + element.address.streetNumber}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="zipCode">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="zip-code"> PLZ </mat-header-cell>
      <mat-cell *matCellDef="let element" class="zip-code"> {{element.address.zipCode}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="city">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="city"> Stadt </mat-header-cell>
      <mat-cell *matCellDef="let element" class="city"> {{element.address.city}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="notes">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="notes"> Notizen </mat-header-cell>
      <mat-cell *matCellDef="let element" class="notes"> {{element.notes}} </mat-cell>
    </ng-container>
    
    <ng-container matColumnDef="type">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="type"> Type </mat-header-cell>
      <mat-cell *matCellDef="let element" class="type"> {{element.type}} </mat-cell>
    </ng-container>
    
    <ng-container matColumnDef="problem">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="problem"> Problem </mat-header-cell>
      <mat-cell *matCellDef="let element" class="problem"> {{element.problem}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="userInfo">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="user-info"> Bearbeiter/-in </mat-header-cell>
      <mat-cell *matCellDef="let element" class="user-info"> {{element.createUserFirstName + ' ' + element.createUserLastName}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="details">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let element">
        <button mat-icon-button (click)="viewDetails(element)"><mat-icon>edit</mat-icon></button>
      </mat-cell>
      <mat-cell *matCellDef="let element">
        <button mat-icon-button (click)="delete(element)"><mat-icon>delete</mat-icon></button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="col"></mat-header-row>
    <mat-row *matRowDef="let row; columns: col;"></mat-row>
  </mat-table>

  <mat-paginator [pageSizeOptions]="[7, 14, 28]" showFirstLastButtons></mat-paginator>