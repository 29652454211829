import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ChangePasswordModel } from '../../models/change.password.model';
import { UserService } from '../../services/user.service';
import { ErrorHandler } from '../../common/errorHandler';
import { ResultCode } from '../../enums/resultcode.enum';
import { PrivilegesService } from '../../services/privileges.service';

@Component({
    selector: 'change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.css'],
    providers: [UserService, ErrorHandler, PrivilegesService]
})
export class ChangePasswordComponent {
    isDataAvailable = false;
    messages: Array<string>;
    isError: boolean = false;
    changePasswordModel: ChangePasswordModel;

    constructor(
        private userService: UserService,
        private privilegesService: PrivilegesService,
        private errorHandler: ErrorHandler,
        private router: Router
    ) {
        this.initChangePasswordModel();
    }

    ngOnInit() {
        this.isDataAvailable = true;
    }

    private initChangePasswordModel(): void {
        this.changePasswordModel = new ChangePasswordModel();
        this.changePasswordModel.currentPassword = '';
        this.changePasswordModel.newPassword = '';
        this.changePasswordModel.newPasswordRepeat = '';
    }

    cancel(): void {
        window.history.back();
    }

    save(): void {
        this.isDataAvailable = false;

        this.userService.changePassword(this.changePasswordModel)
            .then(result => {
                this.isDataAvailable = true;

                if (result.code === ResultCode.Ok) {
                    this.isError = false;
                    this.initChangePasswordModel();
                    this.messages = ['Ihr Passwort wurde erfolgreich geändert.'];
                    this.privilegesService.setResetPassword();

                    setTimeout(() => {
                        this.router.navigate(['home']);
                    }, 5000);
                } else {
                    this.isError = true;
                    if (result.code === ResultCode.GraphApiError) {
                        this.messages = ['Ihre Passwort-Änderung wurde nicht akzeptiert.'];
                    } else if (result.code === ResultCode.GraphApiNewPasswordNotAccepted) {
                        this.messages = ['Ihre Passwort-Änderung erfüllt nicht die notwendigen Komplexitätsbedingungen.'];
                    } else {
                        this.messages = result.messages;
                    }
                }
            })
            .catch(error => {
                this.errorHandler.do(error);
            });
    }
}
