import { Address } from './address.model';

export class PosterLocation {
    notes: string;
    type: string;
    problem: string;
    address: Address;
    id: string;
    createUserMailAddress: string;
    createUserFirstName: string;
    createUserLastName: string;
    updateGeoInfo: boolean;
    addressChanged: boolean;

   constructor() {
    this.addressChanged = false;
   }
}
