import { Component, ViewChild, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorHandler } from '../../common/errorHandler';
import { RestApiService } from '../../services/rest-api.service';
import { PosterLocationService } from '../../services/poster.location.service';
import { PosterLocation } from '../../models/poster.location.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatCardModule } from '@angular/material/card';
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { ResultCode } from 'src/app/enums/resultcode.enum';

@Component({
  selector: 'app-home',
  templateUrl: './poster.location.list.component.html',
  styleUrls: ['./poster.location.list.component.css'],
  providers: [PosterLocationService, ErrorHandler]
})
export class PosterLocationListComponent implements OnInit {
  posterLocations: PosterLocation[];
  messages: Array<string>;
  isDataAvailable = false;

  constructor(
    private posterLocationService: PosterLocationService, 
    private router: Router,
    private errorHandler: ErrorHandler)
    { 
    this.posterLocationService.getPosterLocations().subscribe((posterLocations) => {
      this.posterLocations = posterLocations
    });
  }

  ngOnInit(): void {
  }

  viewDetails(posterLocation: PosterLocation) {
    if(posterLocation !== null && posterLocation !== undefined) {
      this.router.navigate(['posterlocation/' + posterLocation.id]);
    }
  }

  delete(posterLocation: PosterLocation) {
    if(posterLocation !== null && posterLocation !== undefined) {
      this.posterLocationService.delete(posterLocation.id)
      .then(result => {
          if (result.code === ResultCode.Ok) {
              this.messages = null;
              this.posterLocationService.getPosterLocations().subscribe((posterLocations) => {
                this.posterLocations = posterLocations
              });
          } else {
              this.messages = result.messages;
              this.isDataAvailable = true;
          }
      })
      .catch(error => {
          this.errorHandler.do(error);
          this.isDataAvailable = true;
      });

    }
  }
}
