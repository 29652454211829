import { Component, ViewChild, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorHandler } from '../../common/errorHandler';
import { PosterLocationService } from '../../services/poster.location.service';
import { PosterLocation } from '../../models/poster.location.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { ResultCode } from 'src/app/enums/resultcode.enum';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [PosterLocationService, ErrorHandler]
})
export class HomeComponent implements OnInit {
  posterLocations: PosterLocation[];
  col: string[] = ['street','city', 'zipCode', 'notes','type', 'problem', 'userInfo','details'];
  dataSource = new MatTableDataSource<PosterLocation>(this.posterLocations);
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
  );
  messages: Array<string>;
  isDataAvailable = false;

  constructor(
    private posterLocationService: PosterLocationService, 
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private errorHandler: ErrorHandler)
    { 
    this.posterLocationService.getPosterLocations().subscribe((res) => {
    this.dataSource = new MatTableDataSource<PosterLocation>(res);
    setTimeout(() => {
        this.dataSource.paginator = this.paginator;
      }, 0);
    })
  }

  ngOnInit(): void {
    this.posterLocationService.getPosterLocations().subscribe((posterLocations) => {
      this.posterLocations = posterLocations
    });
  }

  viewDetails(posterLocation: PosterLocation) {
    this.router.navigate(['posterlocation/' + posterLocation.id]);
  }

  delete(posterLocation: PosterLocation) {
    if(posterLocation !== null && posterLocation !== undefined) {
      this.posterLocationService.delete(posterLocation.id)
      .then(result => {
          if (result.code === ResultCode.Ok) {
              this.messages = null;
              this.posterLocationService.getPosterLocations().subscribe((posterLocations) => {
                this.posterLocations = posterLocations
              });
          } else {
              this.messages = result.messages;
              this.isDataAvailable = true;
          }
      })
      .catch(error => {
          this.errorHandler.do(error);
          this.isDataAvailable = true;
      });

    }
  }
}
