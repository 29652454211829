<form role="form">
<div class="vote-container md-form">
<p>
  <mat-form-field appearance="fill" class="form-field">
    <mat-label>Notizen</mat-label>
    <input matInput placeholder="bitte ausfüllen" id="notes" name="notes" [(ngModel)]="posterLocation.notes">
    <mat-hint>Welche markanten Stellen gibt es in der Nähe? Strassenecke?</mat-hint>
  </mat-form-field>
</p>
<p>
  <mat-form-field appearance="fill" class="form-field">
    <mat-label>Typ</mat-label>
    <input matInput placeholder="bitte ausfüllen" id="type" name="type" [(ngModel)]="posterLocation.type">
    <mat-hint>Kubicki, Schule, Freiheit oder Fortschritt</mat-hint>
  </mat-form-field>
</p>
<p>
  <mat-form-field appearance="fill" class="form-field">
    <mat-label>Problem</mat-label>
    <input matInput placeholder="bitte ausfüllen" id="problem" name="problem" [(ngModel)]="posterLocation.problem">
    <mat-hint>Schief, Runtergefallen, weg, beschmiert</mat-hint>
  </mat-form-field>
</p>
<p>
  <mat-form-field appearance="fill" class="form-field">
    <mat-label>Strasse</mat-label>
    <input matInput placeholder="bitte ausfüllen" (change)="onAddressChanged()" id="street" name="street" [(ngModel)]="posterLocation.address.street">
  </mat-form-field>
</p>
<p>
  <mat-form-field appearance="fill" class="form-field">
    <mat-label>Hausnummer</mat-label>
    <input matInput placeholder="bitte ausfüllen" (change)="onAddressChanged()" id="streetNumber" name="streetNumber" [(ngModel)]="posterLocation.address.streetNumber">
  </mat-form-field>
</p>
<p>
  <mat-form-field appearance="fill" class="form-field">
    <mat-label>PLZ</mat-label>
    <input matInput placeholder="bitte ausfüllen" id="zipCode" name="zipCode" [(ngModel)]="posterLocation.address.zipCode">
  </mat-form-field>
</p>
<p>
  <mat-form-field appearance="fill" class="form-field">
    <mat-label>Ort</mat-label>
    <input matInput placeholder="bitte ausfüllen" id="city" name="city" [(ngModel)]="posterLocation.address.city">
  </mat-form-field>
</p>
<p>
  <mat-form-field appearance="fill" class="form-field">
    <mat-label>Longitude</mat-label>
    <input matInput placeholder="bitte ausfüllen" id="longitude" name="longitude" [(ngModel)]="posterLocation.address.longitude">
  </mat-form-field>
</p>
<p>
  <mat-form-field appearance="fill" class="form-field">
    <mat-label>Latitude</mat-label>
    <input matInput placeholder="bitte ausfüllen" id="latitude" name="latitude" [(ngModel)]="posterLocation.address.latitude">
  </mat-form-field>
</p>
<p>
    <mat-checkbox id="updateGeoInfo" name="updateGeoInfo" [(ngModel)]="posterLocation.updateGeoInfo">Geo-Info aktualisieren</mat-checkbox>
</p>
<p>
  <button mat-raised-button color="primary" (click)="update()" class="btn btn-default vote-button">Aktualisieren</button>
  <button mat-raised-button color="primary" (click)="add()" class="btn btn-default vote-button">neuer Eintrag</button>
</p>
</div>
</form>