<ul>
  <li *ngFor="let posterLocation of posterLocations" [class.selected]="posterLocation.selected" (click)="viewDetails(posterLocation)">
    <mat-card class = "tp-card vote-card">
      <mat-card-header>
         <div mat-card-avatar class = "tp-header-image"></div>
         <mat-card-title>{{ posterLocation.address.street + ' ' + posterLocation.address.streetNumber }}</mat-card-title>
         <mat-card-subtitle>{{ posterLocation.address.zipCode + ' ' + posterLocation.address.city + ', ' + posterLocation.address.district }}</mat-card-subtitle>
      </mat-card-header>
      <!--img mat-card-image src = "https://www.tutorialspoint.com/materialize/src/html5-mini-logo.jpg" alt = "Learn HTML5"-->
      <mat-card-content>
        <div *ngIf="posterLocation.notes !== null && posterLocation.notes !== undefined && posterLocation.notes.length > 0" class="notes">
          <div class="label">
            Notizen:
          </div>
          <div class="content">
            {{ posterLocation.notes}}
          </div>
        </div>
        <div *ngIf="posterLocation.type !== null && posterLocation.type !== undefined && posterLocation.type.length > 0" class="type">
          <div class="label">
            Typ:
          </div>
          <div class="content">
            {{ posterLocation.type}}
          </div>
        </div>
        <div *ngIf="posterLocation.problem !== null && posterLocation.problem !== undefined && posterLocation.problem.length > 0" class="problem">
          <div class="label">
            Problem:
          </div>
          <div class="content">
            {{ posterLocation.problem}}
          </div>
        </div>
        <div class="geo-position">
          <div class="label">
            Geo-Position:
          </div>
          <div class="content">
            {{ posterLocation.address.latitude + ' ' + posterLocation.address.longitude }}
          </div>
        </div>
        <div *ngIf="posterLocation.createUserFirstName !== null && posterLocation.createUserFirstName !== undefined" class="create-user">
          <div class="label">
            Erfasser/-in:
          </div>
          <div class="content">
            {{ posterLocation.createUserFirstName + ' ' + posterLocation.createUserLastName }}
          </div>
        </div>
      </mat-card-content>
      <mat-card-actions>
         <button mat-icon-button (click)="viewDetails(posterLocation)"><mat-icon>edit</mat-icon></button>
         <button mat-icon-button (click)="delete(posterLocation)"><mat-icon>delete</mat-icon></button>
      </mat-card-actions>
   </mat-card>
  </li>
</ul>