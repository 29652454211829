<agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="20">
    <agm-marker *ngFor="let marker of markers" [latitude]="marker.lat" [longitude]="marker.lng" [label]="marker.label" (markerClick)="clickedMarker()" [iconUrl]="marker.iconUrl">
        
        <agm-info-window #window>
            <h4>{{ marker.infoHead }}<br />
                {{ marker.infoText }}<br />
                {{ marker.typeText }}<br />
                {{ marker.warningText }}
            </h4>
            <a *ngIf="isAuthenticated()" routerLink="/posterlocation/{{ marker.id }}"><mat-icon>edit</mat-icon></a>
          </agm-info-window>
    </agm-marker>
</agm-map>