import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { ContentHeaders } from '../common/headers';
import { AppSettings } from '../common/app.settings';
import { ApiResult } from '../models/apiresult.model';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';

import { PrivilegesModel } from '../models/privileges.model';

@Injectable()
export class PrivilegesService {
    private privileges: PrivilegesModel;
    private apiController = 'Privileges';

    constructor(
        private http: HttpClient,
        private contentHeaders: ContentHeaders,
        private cookieService: CookieService) {
    }

    private getCoockieOptionsSecure(): boolean {
        if (!(window.location.href.indexOf('localhost') > 0)) {
            return true;
        }

        return false;
    }

    private getDomain(): string {   
        const cookieOptionsSecure = this.getCoockieOptionsSecure();     
        let domain = window.location.hostname;
        if(!cookieOptionsSecure) {
            domain = 'localhost';
        }

        return domain;
    }

    getPrivileges(): Observable<PrivilegesModel> {
        console.log(AppSettings.apiEndPoint + '/' + this.apiController);
        return this.http.get<PrivilegesModel>(
                AppSettings.apiEndPoint + '/' + this.apiController,
                { headers: this.contentHeaders.headers }
            )
            .map((response) => {
                this.privileges = response;
                return response;
            });
    }

    setPrivileges(): void {
        const cookieOptionsSecure = this.getCoockieOptionsSecure();
        const domain = this.getDomain();
        this.cookieService.set('menu', this.privileges.menuEntries.join(','), 20, '/', domain, cookieOptionsSecure);
    }

    hasMenuPrivileges(): boolean {
        if (this.cookieService.get('menu') === undefined) {
            return false;
        }

        return true;
    }

    setMenuPrivileges(): void {
        const cookieOptionsSecure = this.getCoockieOptionsSecure();
        const domain = this.getDomain();
        this.cookieService.set('menu', this.privileges.menuEntries.join(','), 20, '/', domain, cookieOptionsSecure);
    }

    setResetPassword(): Observable<ApiResult> {
        return this.http.post<ApiResult>(
            AppSettings.apiEndPoint + this.apiController + '/SetResetPassword',
                {},
                { headers: this.contentHeaders.headers }
            )
            .map((response) => response);
    }

    isAuthorized(menuEntry: string): boolean {

        if (!this.hasMenuPrivileges()) {
            return false;
        }

        const menuEntries = this.cookieService.get('menu').split(',');
        return menuEntries.indexOf(menuEntry) !== -1;
    }

    logOut(): void {
        this.removePrivileges();
    }

    private removePrivileges(): void {
        this.cookieService.delete('menu');
    }

    private handleErrorPromise(error: any): Promise<void> {
        try {
            error = JSON.parse(error._body);
        } catch (e) {
        }

        const errMsg = error.errorMessage
            ? error.errorMessage
            : error.message
            ? error.message
            : error._body
            ? error._body
            : error.status
            ? `${error.status} - ${error.statusText}`
            : 'unknown server error';

        return Promise.reject(errMsg);
    }
}
