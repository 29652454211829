import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppSettings } from '../common/app.settings';
import 'rxjs/add/operator/map';

import { ContentHeaders } from '../common/headers';
import { User } from '../models/user.model';
import { ChangePasswordModel } from '../models/change.password.model';
import { ResetPasswordModel } from '../models/reset.password.model';
import { CaptchaModel } from '../models/captcha.model';
import { ApiResult } from '../models/apiresult.model';

@Injectable()
export class UserService {
    private apiController = '/User';

    constructor(
        private http: HttpClient, private contentHeaders: ContentHeaders) {
    }

    getUsers(): Promise<User[]> {

        // get users from api
        return this.http.get<User[]>(AppSettings.apiEndPoint + this.apiController + '/GetUsers',
            { headers: this.contentHeaders.headers }
        )
            .map((response) => {
                return response;
            })
            .toPromise()
            .catch(this.handleErrorPromise);
    }

    getCaptchaImage(): Promise<CaptchaModel> {
        return this.http.get(
            AppSettings.apiEndPoint + this.apiController + '/GetCaptcha',
                { headers: this.contentHeaders.headers }
            )
            .map((response: Response) => response)
            .toPromise()
            .catch(this.handleErrorPromise);
    }

    changePassword(changePasswordModel: ChangePasswordModel): Promise<ApiResult> {
        console.log(changePasswordModel);
        return this.http.post(
                AppSettings.apiEndPoint + this.apiController + '/ChangePassword',
                JSON.stringify(changePasswordModel),
                { headers: this.contentHeaders.headers }
            )
            .map((response: Response) => response)
            .toPromise()
            .catch(this.handleErrorPromise);
    }

    getUser(userId: string): Promise<User> {
        return this.http.get(AppSettings.apiEndPoint + this.apiController + '/' + userId,
                { headers: this.contentHeaders.headers }
            )
            .map((response: Response) => response)
            .toPromise()
            .catch(this.handleErrorPromise);
    }

    trackLogin(): Promise<ApiResult> {
        return this.http.post(
            AppSettings.apiEndPoint + this.apiController + '/TrackLogin',
                {},
                { headers: this.contentHeaders.headers }
            )
            .map((response: Response) => response)
            .toPromise()
            .catch(this.handleErrorPromise);
    }

    requestPasswordReset(resetPasswordModel: ResetPasswordModel): Promise<ApiResult> {
        return this.http.post(
                AppSettings.apiEndPoint + this.apiController + '/RequestPasswordReset',
                JSON.stringify(resetPasswordModel),
                { headers: this.contentHeaders.headers }
            )
            .map((response: Response) => response)
            .toPromise()
            .catch(this.handleErrorPromise);
    }

    resetPassword(token: string): Promise<ApiResult> {
        return this.http.post(
                AppSettings.apiEndPoint + this.apiController + '/ResetPassword?token=' + token,
                {},
                { headers: this.contentHeaders.headers }
            )
            .map((response: Response) => response)
            .toPromise()
            .catch(this.handleErrorPromise);
    }

    updateUser(user: User): Promise<ApiResult> {
        return this.http.post(
            AppSettings.apiEndPoint + this.apiController + '/Update/' + user.id,
                JSON.stringify(user),
                { headers: this.contentHeaders.headers }
            )
            .map((response: Response) => response)
            .toPromise()
            .catch(this.handleErrorPromise);
    }

    addUser(user: User): Promise<ApiResult> {
        return this.http.post(
            AppSettings.apiEndPoint + this.apiController + '/Add',
                JSON.stringify(user),
                { headers: this.contentHeaders.headers }
            )
            .map((response: Response) => response)
            .toPromise()
            .catch(this.handleErrorPromise);
    }

    deleteUser(userId: string): Promise<ApiResult> {
        return this.http.post(
            AppSettings.apiEndPoint + this.apiController + '/Delete/' + userId,
            { headers: this.contentHeaders.headers }
            )
            .map((response: Response) => response)
            .toPromise()
            .catch(this.handleErrorPromise);
    }

    private handleErrorPromise(error: any): Promise<any> {
        try {
            error = JSON.parse(error._body);
        } catch (e) {
        }

        const errMsg = error.errorMessage
            ? error.errorMessage
            : error.message
            ? error.message
            : error._body
            ? error._body
            : error.status
            ? `${error.status} - ${error.statusText}`
            : 'unknown server error';

        console.error(errMsg);
        return Promise.reject(errMsg);
    }
}
