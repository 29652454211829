import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppSettings } from '../common/app.settings';
import 'rxjs/add/operator/map';

import { PosterLocation } from '../models/poster.location.model'
import { PosterLocationInput } from '../models/poster.location.input.model'
import { ApiResult } from '../models/apiresult.model';
import { ContentHeaders } from '../common/headers';

@Injectable()
export class PosterLocationService {
    private apiController = '/PosterLocation';

    constructor(
        private http: HttpClient,
        private contentHeaders: ContentHeaders) {
    }

    getPosterLocationsByGeoPosition(input: PosterLocationInput): Promise<ApiResult> {
        return this.http.post<ApiResult>(
            AppSettings.apiEndPoint + this.apiController + '/GetPosterLocationsByGeoPosition/',
            JSON.stringify(input),
            { headers: this.contentHeaders.headers }
            )
            .map((response) => response)
            .toPromise();
    }

    getPosterLocations(): Observable<PosterLocation[]> {
        // get users from api
        return this.http.get<PosterLocation[]>(AppSettings.apiEndPoint + this.apiController + '/GetPosterLocations', { headers: this.contentHeaders.headers });
    }

    get(posterLocationId: string): Promise<PosterLocation> {
        return this.http.get<PosterLocation>(
            AppSettings.apiEndPoint + this.apiController + '/' + posterLocationId,
            { headers: this.contentHeaders.headers }
            )
            .map((response) => response)
            .toPromise();
    }

    update(posterLocation: PosterLocation): Promise<ApiResult> {
        return this.http.put<ApiResult>(
            AppSettings.apiEndPoint + this.apiController + '/' + posterLocation.id,
                JSON.stringify(posterLocation),
                { headers: this.contentHeaders.headers }
            )
            .map((response) => response)
            .toPromise();
    }

    add(posterLocation: PosterLocation): Promise<ApiResult> {
        return this.http.post<ApiResult>(
            AppSettings.apiEndPoint + this.apiController + '/',
                JSON.stringify(posterLocation),
                { headers: this.contentHeaders.headers }
            )
            .map((response) => response)
            .toPromise();
    }

    delete(posterLocationId: string): Promise<ApiResult> {
        return this.http.delete<ApiResult>(
            AppSettings.apiEndPoint + this.apiController + '/' + posterLocationId,
            { headers: this.contentHeaders.headers }
            )
            .map((response) => response)
            .toPromise();
    }

    private handleErrorPromise(error: any): Promise<void> {
        try {
            error = JSON.parse(error._body);
        } catch (e) {
        }

        const errMsg = error.errorMessage
            ? error.errorMessage
            : error.message
            ? error.message
            : error._body
            ? error._body
            : error.status
            ? `${error.status} - ${error.statusText}`
            : 'unknown server error';

        console.error(errMsg);
        return Promise.reject(errMsg);
    }
}
