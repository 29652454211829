<ng-container>
  <mat-toolbar color="accent" class="header">      
    <button
      type="button"
      aria-label="Toggle sidenav"
      mat-icon-button
      (click)="drawer.toggle()"
      *ngIf="isHandset$ | async"
    >
      <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>        
    </button>
    <img height='60px' src='/assets/FDP_Bund_BU_GMW_rgb.png'>
    <span class="rightSpace">Plakate</span>    
  </mat-toolbar>
  <mat-sidenav-container class="sidenav-container">  
    <mat-sidenav
      #drawer
      class="sidenav"
      [fixedInViewport]="options.value.fixed" [fixedTopGap]="options.value.top"
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="(isHandset$ | async) === false"
    >
      <mat-nav-list>
        <a *ngIf="isAuthenticated()" mat-list-item routerLink="posterlocations">Liste</a>
        <a mat-list-item routerLink="map">Karte</a>
        <a *ngIf="isAuthenticated()" mat-list-item routerLink="home">Tabelle</a>
        <a *ngIf="isAuthenticated()" mat-list-item routerLink="posterlocation/null">Erfassen</a>
        <a *ngIf="!isAuthenticated()" mat-list-item routerLink="login">Login</a>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</ng-container>