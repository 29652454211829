import { Component, OnInit, ViewChild, ElementRef, NgZone, ComponentFactoryResolver } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { ErrorHandler } from '../../common/errorHandler';
import { AuthenticationService } from '../../services/authentication.service';
import { PosterLocationService } from '../../services/poster.location.service';
import { PosterLocation } from '../../models/poster.location.model';
import { PosterLocationInput } from '../../models/poster.location.input.model';
import { MarkerModel } from '../../models/marker.model';
import { last } from 'rxjs/operators';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css'],
  providers: [PosterLocationService, ErrorHandler]
})
export class MapComponent implements OnInit {
  title: string = 'Map';
  latitude: number;
  longitude: number;
  zoom:number;
  posterLocation: PosterLocation;
  markers: Array<MarkerModel>;
  private geoCoder;

  constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private posterLocationService: PosterLocationService,
    private authService: AuthenticationService,
    private errorHandler: ErrorHandler) { }

  ngOnInit(): void {//load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;
      this.setCurrentLocation();
    });
  }

  private initMap(): void {
    this.markers = new Array<MarkerModel>();
    let defaultIcon = '/assets/icons/beachflag.png';
    if(this.isAuthenticated()) {
      this.markers.push( new MarkerModel(this.posterLocation,'','', ' aktuelle Position', ''));
    }
    let infoText ='';    
    let typeText =''; 
    let warningText =''; 
    this.posterLocationService.getPosterLocations().subscribe((posterLocations) => {
      posterLocations.forEach((posterLocation => {
        infoText = posterLocation.notes !== null && posterLocation.notes !== undefined && posterLocation.notes.length > 0 ? posterLocation.notes : '';
        typeText = posterLocation.type !== null && posterLocation.type !== undefined && posterLocation.type.length > 0 ? posterLocation.type : '';
        warningText = posterLocation.problem !== null && posterLocation.problem !== undefined && posterLocation.problem.length > 0 ? posterLocation.problem : '';
        
        this.markers.push( new MarkerModel(posterLocation, infoText,typeText, warningText, this.getIconUrl(posterLocation)));
      }))
    });
  }

  isAuthenticated(): boolean {
    return !this.authService.isTokenExpired();
  }

  private clickedMarker(): void {
    console.log('click');
  }

  // Get Current Location Coordinates
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 15;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  private getAddress(latitude: number, longitude: number) {
    let input: PosterLocationInput = new PosterLocationInput();
    input.latitude = latitude;
    input.longitude = longitude;
    console.log('getAddress');
    if(this.isAuthenticated()) {
      this.posterLocationService.getPosterLocationsByGeoPosition(input)
          .then(apiResult => {
            let posterLocations: PosterLocation[] = apiResult.data;
            this.posterLocation = posterLocations[0];
            this.initMap();
            //this.isDataAvailable = true;
          })
          .catch(error => {
              this.errorHandler.do(error);
          });
        } else {
          this.initMap();
        }
  }

  private getIconUrl(posterLocation: PosterLocation) {    
    let iconUrl = '/assets/icons/greenflag.png';
    if(posterLocation.problem !== null && posterLocation.problem !== undefined && posterLocation.problem.length > 0) {
      iconUrl = '/assets/icons/redflag.png';
    } else if (posterLocation.type !== null && posterLocation.type !== undefined && posterLocation.type.toLowerCase() === 'gruppe2') {
      iconUrl = '/assets/icons/yellowflag.png';
    } else if (posterLocation.type !== null && posterLocation.type !== undefined && posterLocation.type.toLowerCase() === 'gruppe1') {
      iconUrl = '/assets/icons/blueflag.png';
    }

    return iconUrl;
  }
}
