import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ContentHeaders } from '../../common/headers';
import { LoginModel } from '../../models/login.model';
import { AppSettings } from '../../common/app.settings';
import { PrivilegesService } from '../../services/privileges.service';
import { AuthenticationService } from '../../services/authentication.service';
import { ApiResult } from 'src/app/models/apiresult.model';

@Component({
  selector: 'logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css'],
  providers: [PrivilegesService]
})
export class LogoutComponent {
  constructor(private router: Router,
    private http: HttpClient,
    private privilegesService: PrivilegesService,
    private authService: AuthenticationService) { }

  ngOnInit() {
    this.privilegesService.logOut();
    this.authService.logout();
    this.http.post<ApiResult>(AppSettings.apiEndPoint + '/logout', { headers: ContentHeaders })
      .subscribe(
        response => {
          console.log(response);
        },
        error => {
        }
      );
    this.router.navigate(['login']);
  }
}
