export class Address {
    
    id: string;
    country: string;
    district: string;
    state: string;
    street: string;
    streetNumber: string;
    city: string;
    zipCode: string;
    longitude: string;
    latitude: string;
   constructor() {
   }
}
