<div class="login">
  <h1>Anmelden</h1>
  <form role="form" (submit)="login($event, username.value, password.value)">
    <div *ngIf="error" class="alert alert-danger">{{error}}</div>
    <p>
      <mat-form-field appearance="legacy">
        <mat-label class="login-label">Benutzer</mat-label>
        <input matInput placeholder="bitte ausfüllen" id="username" name="username" #username>
        <mat-hint>Geben Sie hier den Benutzernamen ein.</mat-hint>
      </mat-form-field>
    </p>
    <p>
      <mat-form-field appearance="legacy">
        <mat-label class="login-label">Passwort</mat-label>
        <input matInput type="password" placeholder="bitte ausfüllen" id="password" name="password" #password>
        <mat-hint>Geben Sie hier das Passwort ein.</mat-hint>
      </mat-form-field>
    </p>
    <div class="text-right">
      <div><button mat-raised-button color="primary" type="submit" class="btn btn-default">Anmelden</button></div>
    </div>
    <!--div class="row back-link">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <a [routerLink]="['/resetPassword']">Passwort vergessen</a>
        </div>
    </div-->
  </form>
</div>
