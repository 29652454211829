import { HttpHeaders } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class ContentHeaders {

    constructor(private authService: AuthenticationService) {

    }

    get headers(): HttpHeaders {

        let token = this.authService.getToken();

        return new HttpHeaders({
            'name': 'header',
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token
        });
    }

    public headersWithContentType(contentType: string): HttpHeaders {

        let token = this.authService.getToken();

        return new HttpHeaders({
            'name': 'header',
            'Content-Type': contentType,
            'Accept': contentType,
            'Authorization': 'Bearer ' + token
        });
    }

    public headersWithoutContentType(): HttpHeaders {

        let token = this.authService.getToken();

        return new HttpHeaders({
            'name': 'header',
            'Authorization': 'Bearer ' + token
        });
    }
}

