import { Component, ViewChild } from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { AuthenticationService } from './services/authentication.service';
import { MatSidenav } from '@angular/material/sidenav';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Observable } from "rxjs";
import { map, filter, withLatestFrom, shareReplay } from 'rxjs/operators';
import { AgmCoreModule } from '@agm/core';
import { AppSettings } from './common/app.settings';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  title = 'whatnext';
  options: FormGroup;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('drawer') drawer: MatSidenav;
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(private breakpointObserver: BreakpointObserver, private authService: AuthenticationService,
    router: Router,fb: FormBuilder) {
    this.setAppSettings();
    this.options = fb.group({
      bottom: 0,
      fixed: false,
      top: 0
    });
    router.events.pipe(
      withLatestFrom(this.isHandset$),
      filter(([a, b]) => b && a instanceof NavigationEnd)
    ).subscribe(_ => this.drawer.close());
  }

  isAuthenticated(): boolean {
    return !this.authService.isTokenExpired();
  }

  private setAppSettings(): void {
    console.log(environment.apiEndPoint);
      AppSettings.apiEndPoint = environment.apiEndPoint; //'http://localhost:62261/'
      AppSettings.envName = environment.envName;
  }
}